.main{
  background-color: #f8f6f6;
  flex: 1 0 auto;
  @media screen and (max-height: 650px){
     min-height: calc(100vh - 113px);
  }

  &.first{
    background: url("/img/design/aoe-first-bg.jpg") no-repeat center;
    background-size: cover;
  }
}