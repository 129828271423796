.footer{
  flex: 0 0 auto;
  height: 56px;
  background-color: #f0eeee;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 90;

  .copyright{
    text-align: center;
    font-family: $ff-khula;
    font-size: 14px;
    color: #2e3d49;
    margin: 0;
  }
}