.headset-diagnostics {
  height: 100%;
  width: 100%;
  font-family: "PT Sans", sans-serif;

  &__wrapper {
    height: 100%;
    border: 1px solid #E5E4E5;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    padding: 30px;
    min-width: 1300px;
  }

  &__items {
    display: flex;
    margin-bottom: 90px;
  }

  &__item {
    flex-basis: 50%;
    min-width: 50%;

    &-title {

    }

    &-content {
      display: flex;

      span {
        font-size: 16px;
        color: #272727;
        line-height: 25px;
        font-weight: 400;

        &:last-child {
          margin-left: 5px;
        }
      }


      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  &__camera {
    display: flex;
    align-items: center;

    &--offline {
      opacity: .5;
    }

    &-info {
      margin-left: 30px;
    }

    &-item {
      display: flex;

      &:first-child {
        margin-bottom: 10px;
      }
    }

    &-title {
      font-weight: 400;
      font-size: 16px;
      color: #272727;
    }

    &-status {
      font-weight: 600;
      font-size: 16px;
      margin-left: 10px;
      color: #979797;

      &--ok {
        color: #2EB398;
      }

      &--error {
        color: #FF5367;
      }
    }
  }

  &__front {
    position: relative;


    &-status {
      position: absolute;
      top: 15%;
      left: 10%;
      z-index: 99;
    }
  }

  &__iris {
    position: relative;

    &-status {
      position: absolute;
      bottom: calc(35% - 28px);
      right: calc(20% - 28px);
      z-index: 99;
    }
  }

  &__cross {
    position: relative;

    &-status {
      position: absolute;
      bottom: 25%;
      right: calc(20% - 30px);
    }
  }

  &__offline {
    color: #FF5367;
    fill: #FF5367;
    stroke: #FF5367;

    path {
      fill: #FF5367;
      stroke: #FF5367;
    }
  }

  &__online {
    color: #2EB398;
    fill: #2EB398;
    stroke: #2EB398;

    path {
      fill: #2EB398;
      stroke: #2EB398;
    }
  }

  &__na {
    color: #979797;
    fill: #979797;
    stroke: #979797;

    path {
      fill: #979797;
      stroke: #979797;
    }
  }
}
