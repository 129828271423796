.breadcrumb{
  background-color: #fff;
  @include mq('1300', max) {
    padding: 4px 0;
  }
  &__list>li+li:before {
    content: ">";
    color: #6b6a6c;
    width: 8px;
    display: inline-block;
    padding: 6px;
    font-size: 14px;
  }

  &__item{
    display: inline-block;

    a,span{
      font-family: $ff-khula;
      color: #6b6a6c;
      font-size: 14px;
      font-weight: 400;
    }
    a{
      &:hover{
        color: $c-green;
      }
    }
  }
}