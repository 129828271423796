.form{

  &-wrapper{
    background-color: $c-white;
    width: 100%;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #c6c3c6;
  }

  &-title{
      background-color: #f0eded;
      padding: 10px 0 10px 15px;
      font-size: 16px;
      font-weight: 400;
      font-family: $ff-khula;
      line-height: 16px;
      color: $c-black;
      border-bottom: solid 1px #c6c3c6;
  }

  &-inner{
    padding: 30px 50px;
    @include mq('768', max) {
      padding: 20px 30px;
    }
    @include mq('480', max) {
      padding: 10px 15px;
    }

    &__errors{
      margin-bottom: 15px;
      .help-block{
        font-size: 14px;
        font-family: $ff-khula;
        color: $c-error-form;
        font-weight: 400;
      }
    }
  }

  // form element

  &__label{
    min-width: 140px;
    width: 13%;
    margin-right: 15px;
    text-align: right;
    font-family: $ff-khula;
    font-weight: 700;
    color: $c-black;
    font-size: 16px;
    &.required{
      position: relative;
      &:before{
        display: inline-block;
        content: "\00a0*";
        position: absolute;
        right: -7px;
        top: 0;
        font-size: 16px;
        color: #F35E5E;
        font-family: $ff-khula;
        @include mq('480', max) {
          right: -10px;
          font-size: 20px;
        }
      }
    }

    &--large{
      min-width: 140px;
    }

    @include mq('480', max) {
      width: auto;
      min-width: auto;
      text-align: left;
      margin: 0 0 5px 0;
    }
  }

  &__info{
    font-size: 16px;
    color: $c-black;
    font-weight: 400;
    font-family: $ff-khula;
    max-width: calc(100% - 13% - 15px);
    a{
      color: $c-black;
      text-decoration: underline;
    }
  }

  &__input{
    position: relative;
    @include mq('768', max) {
      width: 100%;
    }

    &--radio{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      &:hover{
        cursor: pointer;
      }

      label{
        font-size: 16px;
        font-family: $ff-khula;
        font-weight: 400;
        color: $c-black;
      }

      &-first{
        margin-right: 30px;
      }

      .jcf-radio{
        width: 16px;
        height: 16px;
        background-color: #f0eeee;
        border: solid 1px #979797;
        border-radius: 50%;
        margin: 0 8px 0 0;
        position: relative;

        input{
          line-height: 1;
        }

        &.jcf-checked{
          border: solid 1px $c-green;
          background-color: $c-white;

          span{
            width: 10px;
            height: 10px;
            background-color: $c-green;
            @include centerer-xy;
            bottom: auto;
            right: auto;
            margin: auto !important;
            padding: auto !important;
          }
        }

      }
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="password"]{
      width: 363px;
      height: 32px;
      border-radius: 3px;
      background-color: #ffffff;
      border: solid 1px #c6c3c6;
      font-family: $ff-khula;
      line-height: 1;
      font-size: 16px;
      color: $c-black;
      font-weight: 300;
      padding-left: 8px;
      &:focus{
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        border: solid 1px #479efe !important;
      }
      @include mq('768', max) {
        width: 100%;
      }
    }

    textarea{
      width: 363px;
      height: 132px;
      border-radius: 3px;
      background-color: #ffffff;
      border: solid 1px #c6c3c6;
      resize: none;
      font-size: 16px;
      color: $c-black;
      font-weight: 300;
      padding: 5px 8px;
      font-family: $ff-khula;
      line-height: 1.32;
      &:focus{
        outline: none;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        border: solid 1px #479efe !important;
      }
      @include mq('768', max) {
        width: 100%;
      }
    }

    .textarea{
      &--resize{
        min-width: 363px;
        width: 1000px;
        max-width:  1000px;
        min-height: 130px;
        resize: both;
        @include mq('1300', max) {
          max-width: 750px;
        }
        @include mq('1025', max) {
          max-width: 540px;
        }
        @include mq('768', max) {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
          resize: vertical;
        }
      }
    }

    .jcf-select{
      width: 363px;
      height: 32px;
      background-color: #ffffff;
      border: solid 1px #c6c3c6;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      &.jcf-focus{
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        border: solid 1px #479efe !important;
        .jcf-select-opener{
          @include triangle(4px, down, #999);
          background-color: transparent !important;
          border-top: 4px solid #999 !important;
        }
      }
      @include mq('768', max) {
        width: 100%;
      }
      .jcf-select-text{
        padding-left: 8px;
        margin: 0;
        line-height: 30px;
        span{
          font-family: $ff-khula;
          font-size: 16px;
          color: $c-black;
          font-weight: 300;
          option{
            font-weight: 300;
          }
        }
      }
      .jcf-select-opener{
        background-color: transparent;
        @include triangle(4px, down, #999);
        border-top: 4px solid #999;
        @include centerer-y;
        right: 13px;
      }
    }

    .select2-container{
      width: 363px !important;
      @include mq('768', max) {
        width: 100% !important;
      }
      .select2-selection__rendered{
        font-family: "PT Sans",sans-serif !important;
        font-size: 16px !important;
        color: #4a4a4a !important;
        font-weight: 300 !important;
      }
    }

    .datepicker-here{
      position: relative;
      background-color: transparent !important;
      z-index: 101 !important;
      &__icon{
        display: inline-block;
        width: 42px;
        height: 30px;
        background-color: #f0eded;
        position: absolute;
        right: 1px;
        top: 1px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        z-index: 90;
        svg{
          width: 22px;
          height: 22px;
          fill: #9b9b9b;
          @include centerer-xy;
        }
      }
    }

    span{
      font-size: 16px;
      color: $c-black;
      font-family: $ff-khula;
    }

  }

  &__btn{
    @include mq('480', max) {
      width: 100%;
      text-align: center;
    }
  }

  &__table{
    width: 1000px;
    background-color: #fbfcfd;
    border: 1px solid #c6c3c6;
    th{
      font-weight: bold;
      font-size: 15px;
    }

    tr{
      border-bottom: 1px solid #c6c3c6;
      &:last-child{
        border-bottom: none;
      }
    }

    thead{
      border-bottom: 1px solid #c6c3c6;
    }

    th,td{
      padding: 10px 20px 10px 10px;
      text-align: left;
      vertical-align: middle;

      div{
        padding: 5px 0;
      }
    }

    tbody{
      padding: 10px;
    }
  }
}

#form__btn{
  display: flex;
}

.select2-container--bootstrap{
  .select2-results__options{
    li{
      font-family: "PT Sans",sans-serif !important;
      font-size: 16px !important;
      color: #4a4a4a !important;
      font-weight: 300 !important;
      &:hover{
        background-color: $c-blue !important;
        color: $c-white !important;
      }
    }
  }
}

.select2-container--bootstrap .select2-results__option--highlighted[aria-selected]{
  background-color: $c-blue !important;
  color: $c-white !important;
}


.jcf-select-drop{
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  background-color: $c-white;
  .jcf-list ul{
     li{
       .jcf-option{
         font-family: "PT Sans",sans-serif !important;
         font-size: 16px !important;
         color: #4a4a4a;
         font-weight: 300 !important;
       }
     }
  }
}

.jcf-list-box .jcf-selected, .jcf-select-drop .jcf-hover{
  background-color: $c-blue !important;
  color: $c-white !important;
}

.jcf-select-drop .jcf-select-drop-content{

  border: 1px solid #479efe!important;
  border-top: none !important;
  position: relative;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.form-group{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 22px;
  @include mq('480', max) {
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;
  }
  &.has-error{
    .help-block{
      display: block;
    }
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="password"]{
      border-color: $c-error-form;
    }

    .form__label{
      padding-bottom: 17px;
      @include mq('480', max) {
        padding-bottom: 0;
      }
    }
  }

  .help-block{
    display: none;
    font-size: 12px;
    color: $c-error-form;
    font-family: $ff-khula;
    padding-top: 5px;
  }
}



.form-add-role{
  .form-group{
    align-items: flex-start;
  }

  .form__label{
    padding-top: 3px;
  }


}

.form-add-checkpoint{
  .form-group{
    margin-bottom: 15px;
    @include mq('480', max) {
      flex-direction: row;
    }
  }

  &__title{
    font-size:18px;
    font-family: $ff-khula;
    font-weight: 500;
    color: $c-black;
    margin-bottom: 15px;
  }

  .jcf-select{
    width: 405px;
    height: 32px;
    border-radius: 3px;
    background-color: #ffffff;
    border: solid 1px #c6c3c6;
    @include mq('768', max) {
      width: 100%;
      padding-right: 30px;
    }
    select{
      &:hover{
        cursor: pointer;
      }
    }
    &.jcf-focus{
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5), inset 1px 1px 2px 0 #479efe;
      border: solid 1px #479efe !important;
      .jcf-select-opener{
        @include triangle(4px, down, #4a4a4a);
        background-color: transparent !important;
        border-top: 6px solid #4a4a4a !important;
      }
    }
    .jcf-select-text{
      padding-left: 8px;
      margin: 0;
      line-height: 30px;
      span{
        font-family: $ff-khula;
        font-size: 16px;
        color: $c-black;
        font-weight: 300;
        option{
          font-weight: 300;
        }
      }
    }
    .jcf-select-opener{
      background-color: transparent;
      @include triangle(4px, down, #4a4a4a);
      border-top: 6px solid #4a4a4a;
      @include centerer-y;
      right: 13px;
    }
  }

  &__main{
    padding:10px 0 10px 35px;
    @include mq('768', max) {
      padding: 0;
    }
  }

  label{
    padding-left: 12px;
    font-size: 16px;
    color: $c-black;
    font-family: $ff-khula;
    font-weight: 400;
  }

  .jcf-checkbox{
    border: solid 1px #c6c3c6;

    input{
      &:hover{
        cursor: pointer;
      }
    }

    &.jcf-checked {
      border-color: #2eb398 !important;
    }
  }

}

#new-rolecheckpoint-form .form__input .select2-container{
  width: 500px !important;
}


.form-exam{
  .form-group:nth-child(9){
    align-items: flex-start;
  }
}

.form-exam-answer{
  .form-group:nth-child(5){
    align-items: flex-start;
  }
}

.form.form-university{
  &-university{
      textarea{
        resize: both;
      }
  }

  .form-group{
    &:nth-child(3),
    &:nth-child(4){
      align-items: flex-start;
    }
  }
}

input.datepicker-here{
  @include placeholder($c-black, 16px , 300);

}

.only-timepicker .datepicker--nav,
.only-timepicker .datepicker--content {
  display: none;
}
.only-timepicker .datepicker--time {
  border-top: none;
}

// file
.jcf-file{
    .jcf-fake-input{
      border: none;
      border-bottom: solid 1px #c5c3c6;
      color: $c-green;
    }

    .jcf-upload-button{
      display: inline-block;
      border: none;
      background: url("/img/design/aoe-attach-file.png") no-repeat center;

      .jcf-button-content{
        font-size: 0;
      }

      &:hover{
        cursor: pointer !important;
      }
    }

  &:hover,
  &:focus{
    cursor: pointer !important;
  }


}