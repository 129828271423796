.wrapper-table{
  h2{
    font-size: 18px;
    font-family: $ff-khula;
    font-weight: 600;
    color: $c-black;
    margin-bottom: 12px;
  }

  .btn-groups{
    .btn-add{
      margin-right: 10px;
    }
  }
}

.table-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  @include mq('768', max) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.table-active{
  font-size: 16px;
  font-family: $ff-khula;
  font-weight: 700;
  color: $c-black;

  &__yes{
    display: inline-block;
    margin: 0 14px 0 4px;
    font-weight: 400;
  }

  &__no{
    display: inline-block;
    font-weight: 400;
  }
}

/* table */

table.table_border{
  @include mq('1025', max) {
    min-width: 992px !important;
    overflow: auto;
  }
}

.table-responsive{
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  margin-top: 8px;
  @include mq('1025', max) {
    overflow: auto;
  }
}

.table-title{
  height: 40px;
  line-height: 40px;
  background-color: #f0eded;
  font-size: 16px;
  font-weight: 400;
  font-family: "PT Sans",sans-serif;
  color: #4a4a4a;
  box-shadow: none;
  border: 1px solid #c6c3c6;
  padding-left: 15px;
  @include mq('1025', max) {
    min-width: 992px;
  }
}

.table_border{
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  .jcf-checkbox{
    border: solid 1px #c6c3c6;

    input{
      &:hover{
        cursor: pointer;
      }
    }

  }

  &-bottom{
      padding: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .jcf-select{
    width: 64px;
    height: 28px;
    border-radius: 3px;
    background-color: #ffffff;
    border: solid 1px #c6c3c6;
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    top: 7px;

    .jcf-select-opener{
      background: none;
      &:before{
        content: '';
        @include triangle(5px, down, #9b9b9b);
        border-top: 6px solid #9b9b9b;
        @include centerer-xy;
      }
    }

    &.jcf-focus{
      .jcf-select-opener{
        &:before{
          content: '';
          @include triangle(5px, down, $c-green);
          border-top: 6px solid $c-green;
        }
      }
    }

    .jcf-select-text{
      margin: 0 26px 0 8px;
      span{
        font-size: 16px;
      }
    }
  }

}

table.table_border th {
  background: $c-white;
  font-size: 16px;
  padding: 8px 15px 8px 15px;
  text-align: left;
  font-weight: 700;
  vertical-align: middle;
  font-family: $ff-khula;
  color: $c-black;
  border: 1px solid #E5E4E5;
  height: 40px;
}

table.table_border td {
  font-family: $ff-khula;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #E5E4E5;
  height: 10px;
  padding: 6px 15px;
  vertical-align: middle;
  color: #434343;
  text-align: center;
  @include mq('1300', max) {
    padding: 6px 5px;
  }
}

table.table_border tbody tr{
  &:hover{
    td{
      background-color: rgba(46,179,152, 0.2);
    }
  }
}

table.table_border tr td{
  &:nth-child(3){
    text-align: left;
  }

  &:nth-child(4){
    text-align: left;
  }

  &:nth-child(5){
    text-align: left;
  }

  &:nth-child(6){
    text-align: left;
  }
}

table.table_border tr.row__disable td{
  &:nth-child(3){
    color: #c6c3c6;
  }
  &:nth-child(4){
    color: #c6c3c6;
  }
  &:nth-child(5){
    color: #c6c3c6;
  }

  &:nth-child(6) {
    color: #c6c3c6;
  }
  &:nth-child(7){
    color: #c6c3c6;
  }
  &:nth-child(8){
    color: #c6c3c6;
  }
  &:nth-child(9){
    color: #c6c3c6;
  }
}

.table_border tr:nth-child(odd) td {
  background: #FBFCFD;
}

table.table_border input[type="text"],
table.table_border input[type="email"]{
  width: 100%;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #c6c3c6;
  font-size: 16px;
  height: 28px;
  line-height: 1 !important;
  font-family: $ff-khula;
  font-weight: 400;
  color: $c-black;
  padding-left: 10px;
  &:focus,
  &:active{
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
    border: solid 1px #479efe;
    outline: none;
  }
}

table.table_border {
  .table_border__input td{

     .jcf-checkbox{
        position: relative;
        left: 3px;
        margin-right: 10px;
     }

     .btn-refresh{
       position: relative;
       top:2px;
     }

     &:first-child{
       text-align: left;

     }

     &:last-child{
       text-align: center;
     }

  }
}

.btn-table{
  width: 64px;
  height: 28px;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #979797;
  font-size: 14px;
  font-weight: 500;
  font-family: $ff-khula;
  color: #4a4a4a;
  //line-height: 28px;
  //margin-right: 12px;
  &:last-child{
    margin-bottom: 0;
  }

  &:not(.not-active){
    @include mq('1300', max) {
      display: block !important;
      margin: 0 auto 5px;
    }
  }

  &.btn-edit{
    padding-left: 28px;
    position: relative;

    svg{
      width: 20px;
      height: 15px;
      position: absolute;
      top: 4px;
      left: 6px;
      fill:  $c-black;
    }
  }

  &.btn-more{
    padding-right: 28px;
    position: relative;
    margin-right: 0;
    @include mq('1300', max) {
      margin: 0 auto 5px;
    }
  }

  &:hover{
    color: $c-green;
    border-color: $c-green;
    cursor: pointer;

    .icon-down{
      border-top-color: $c-green;
    }

    svg{
      fill: $c-green;
    }
  }

  .icon-down{
    @include triangle(4px, down, $c-black);
    border-top: 6px solid #4a4a4a;
    position: absolute;
    top: 11px;
    right: 8px;
  }

  &.btn-check{
    width: 96px;
  }

  &.not-allowed{
    color: #c6c3c6 !important;
    border: 1px solid !important;
    pointer-events: none;
    svg{
      fill: #c6c3c6 !important;
    }

    &:hover {
      cursor: not-allowed;
    }

    i.icon-down{
      border-top: 6px solid #c6c3c6;
    }
  }

  &--auto{
    width: auto;
  }
}

.more__drop-down{
  position: absolute;
  z-index: 12;
  left: 0;
  top: 28px;
  width: 118px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.4);
  border: solid 1px #9b9b9b;
  display: none;

  &.active{
    display: block;
  }

  @include mq('1300', max) {
    width: 112px;
  }

  &.more__drop-down--persons{
    width: 165px;
  }
}

.more{
  &__list{
    margin: 0;
  }

  &__item{


    &:last-child{

      .more__link{
        border-bottom: none;
      }
    }
  }

  &__link{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 0;
    text-decoration: none;
    position: relative;
    color: $c-black;
    font-weight: 500;
    font-size: 14px;
    border-bottom: solid 1px #c6c3c6;
    @include mq('1300', max) {
      justify-content: center;
    }

    &--red{
      color: $c-red;
      svg{
        fill: $c-red;
      }
    }

    &--biometric{
      span{
        text-align: left;
      }
    }

    &:hover{
      color: $c-green;
      outline: none;

      svg{
        fill: $c-green !important;
      }
    }

    svg{
      width: 18px;
      height: 16px;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      transition: $base-transition;
      top: 0;
      @include mq('1300', max) {
        margin-left: 0;
      }
    }

    &.not-allowed{
      span{
        color: #c6c3c6 !important;
      }

      svg{
        fill: #c6c3c6 !important;
      }

      &:hover{
        cursor: not-allowed;
        span{
          color: #c6c3c6 !important;
        }

        svg{
          fill: #c6c3c6 !important;
        }
      }
    }

  }

}

.more-box{
  display: inline-block;
  position: relative;
  @include mq('1300', max) {
    display: block;
  }


}

.switch-table{
  display: inline-block;
  flex-direction: column;
  position: relative;
  width: 12px;
  height: 15px;
  margin-left: 4px;
  top: 3px;
  &__up{
    display: inline-block;
    @include triangle(6px, up, #9b9b9b);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    margin-bottom: 1px;
    position: absolute;
    top: -1px;
    left: 0;
    z-index: 10;
    &:hover{
      border-bottom-color: $c-green;
      cursor: pointer;
    }

    &.active{
      border-bottom-color: $c-black;
    }
  }

  &__down{
    display: inline-block;
    @include triangle(6px, down, #9b9b9b);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    margin-top: 1px;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 10;
    &:hover{
      border-top-color: $c-green;
      cursor: pointer;
    }
    &.active{
      border-top-color: $c-black;
    }
  }
}

.table_border-bottom{
  .show-row{
    select{
      width: 65px;

      &:focus{
        outline: none;
      }

    }
  }
}

.pagination{
  border-radius: 3px;
  border: solid 1px #c6c3c6;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin: 0;

  li{
    border-right: 1px solid #c6c3c6;
    &:last-child{
      border-right: none;
    }
  }

  &__arrow{
    position: relative;
    //border-right: 1px solid;
    height: 30px;
    width: 30px;
    &.disabled{
      opacity: 0.5;
      &:hover{
        cursor: none;
      }
    }
    a{
      display: inline-block;
      padding: 6px;

      svg{
        display: inline-block;
        width: 15px;
        height: 15px;
        position: relative;
        top: 1px;
      }
    }
  }

  &__main{
    position: relative;
    height: 30px;
    width: 70px;

    a{
      @include centerer-x;
      top: 2px;
      text-decoration: none;
      color: $c-black;
      padding: 3px 10px;
      border-radius: 3px;

      display: inline-block;
      line-height: 1;
      font-size: 16px;
      .jcf-number{
        height: 20px;
        .jcf-btn-dec,
        .jcf-btn-inc{
          width: 10px;
          height: 8px;
          display: none;
          background: transparent;
        }

        .jcf-btn-dec{
          &:before{
            border-color: #9b9b9b transparent transparent;
          }
        }

        .jcf-btn-inc{
          &:before{
            border-color: transparent transparent #9b9b9b;
          }
        }

        input{
          border: solid 1px #c6c3c6;
          padding: 0 10px 0 5px;
          font-weight: 400;
          border-radius: 3px;
          font-size: 14px;
          color: $c-black;
          text-align: center;
          width: 45px;
          &:focus{
            outline: none;
        }
      }
        &.jcf-focus{
          border-color: transparent !important;
          input{
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
            border: 1px solid #479efe;
            outline: 0;
          }
          .jcf-btn-dec,
          .jcf-btn-inc{
            display: block !important;
          }
        }
      }

    }

  }
}

table.table_border td.active-cell,
table.table_border th.active-cell{
  background-color: rgba(46,179,152, 0.1);
}

table .table-bottom td{
  background-color: $c-white !important;
}

.table-input-box{
  position: relative;

  button.table-input__close{
    display: none;
    position: absolute;
    background: transparent;
    top: 6px;
    right: 2px;
    svg{
      display: inline-block;
      height: 14px;
      width:  12px;
      fill: $c-red;
      line-height: 1;
    }

    &.show {
      display: inline-block;
    }

    &:hover{
      cursor: pointer;
    }
  }
}

.table tbody.all-checked-row  tr td{
  background-color: rgba(46,179,152,.1);
}

.cell{

  &-btn{
    padding: 6px 5px !important;
  }
  &-group-operations{
    position: relative;
  }
}

.table_border{
  .cell {
    &-group-operations {
      .btn-refresh {
        position: absolute !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
        right: 8px;
      }

      .btn-delete{
        position: relative;
        left: 24px;
      }
    }
  }
}

table.table__add-role {
  td.cell-group-operations {
    .jcf-checkbox {
      position: relative;
      left: 2px !important;
      margin-right: 10px;
    }
  }
  .cell {
    &-action {
      width: 23%;
      @include mq('1025', max) {
        width: auto;
      }
    }
    &-user-role {
      width: 20%;
      @include mq('1025', max) {
        width: auto;
      }
    }
    &-description {
      width: 57%;
      @include mq('1025', max) {
        width: auto;
      }
    }

    &-group-operations {
      .btn-refresh {
        position: absolute !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
        right: 0;
      }
    }

    &-btn {
      text-align: right !important;
    }
  }
}

.table-checkpoint{
  tr{
    td{
      text-align: left;
    }
    th{
      height: 40px;
    }
  }
  &__header{
    justify-content: flex-start;

    .btn-add{
      margin-right: 12px !important;
    }
  }

  .cell {
    &-action {
      width: 13.5%;
      @include mq('1300', max) {
        min-width: 180px;
      }
      @include mq('1025', max) {
        width: auto;
      }
    }

    &-checkpoint{
      width: 42.5%;
      @include mq('1025', max) {
        width: auto;
      }
    }

    &-role{
      width: 8.8%;
      text-align: center !important;
      @include mq('1025', max) {
        width: auto;
      }
    }

    &-btn{
      padding: 6px 5px !important;
      text-align: center;

      .btn-delete{
        padding: 1px 12px 2px;
        height: 28px;
        position: relative;
        top: 1px;
        @include mq('1300', max) {
          display: none !important;
        }
      }
    }
  }
}

.disable-checkpoints{
  opacity: 0.6 !important;
}

table.table__add-role .cell-group-operations .btn-delete{
  @include centerer-none;
  position: relative;
  left: 16px;
}

.table{
  p{
    line-height: 1.4;
  }

  &-user{
    thead tr th{
      &:nth-child(1) {
        width: 18%;
      }
      &:nth-child(2){
        width: 16%;
      }
      &:nth-child(3){
        width: 17%;
      }
      &:nth-child(7){
        width: 9%;
      }
    }

    tbody tr td{
      &:nth-child(4){
        text-align: center;
      }
      &:nth-child(7){
        text-align: left;
      }
    }
  }

  &-info{
    display: flex;
    flex-direction: row;
    @include mq('1300', max) {
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &__list{
      width: 33.3%;
      margin-bottom: 20px;
      @include mq('768', max) {
        width: 50%;
      }
      @include mq('480', max) {
        width: 100%;
      }
    }

    &__item{
      font-size: 16px;
      font-family: $ff-khula;
      line-height: 24px;
      color: $c-black;
      strong{
        font-weight: 600;
      }
    }
  }

  &-person{
    .cell{
      &-action{
        width: 19%;
      }
      &-name{
        width: 24.5%;
      }

      &-active{
        width: 7.5%;
        text-align: center !important;
      }

      &-bio{
        width: 10%;
      }
    }
    tr{
      td{
        &:last-child{
          text-align: center !important;
        }
      }
    }
    .cell-group-operations .btn-delete {
      position: relative;
      left: 40px;
    }
  }

  &-university{
    .cell{
      &-action{
        width: 22%;
        @include mq('1300', max) {
          width: auto;
        }
      }
      &-university{
        width: 60%;
        @include mq('1300', max) {
          width: auto;
        }
      }
      &-persons{
        width: 9%;
        text-align: center !important;
        @include mq('1300', max) {
          width: auto;
        }
      }
      &-schools{
        width: 9%;
        text-align: center !important;
        @include mq('1300', max) {
          width: auto;
        }
      }
    }
  }

  &-university-person{
    &__header{
      justify-content: space-between;

      .btn--blue{
        margin-right: 12px;
      }
    }

    .cell{
      &-action{
        width: 22%;
      }

      &-name{
        width: 21%;
      }

      &-id{
        width: 15%;
      }

    }

    .cell-group-operations .btn-delete{
      left: 22px;
    }

  }

  &-university-school{
    &__header{
      justify-content: flex-start;

      .btn-add{
        margin-right: 12px;
      }
    }

    .cell{
      &-action{
        width: 22%;
      }

      &-name{
        width: 69%;
      }

      &-courses{
        width: 9%;
        text-align: center !important;
      }
    }
  }

  &-university-courses{
    &__header {
      justify-content: space-between;

      .btn-add {
        margin-right: 12px;
      }

      div:nth-child(2){
        display: flex;

        button{
          margin-left: 25px;
          margin-right: 0;
        }
      }

      .table-form__label{
        margin-left: 25px;
      }
    }

    .cell{
      &-action{
        width: 22%;
        @include mq('1300', max) {
          min-width: 265px;
        }
      }

      &-course{
        width: 30%;
      }

      &-code{
        width: 15%;
      }

      &-departmen{
        width: 33%;
      }
    }
  }

  &-lecture{
    &__header{
      align-items: center;
    }

  .cell{

    &-action{
      width: 22%;
      @include mq('1300', max) {
        min-width: 265px;
      }
    }
    &-instructor{
      width: 15%;
    }

    &-course{
      width: 15%;
    }

    &-name{
      width: 20%;
    }

    &-active{
      width: 7%;
    }

    &-content{
      word-break: break-word;

      width: 22%;
    }

  }

    //tr{
    //  th{
    //    padding: 6px 10px;
    //  }
    //  td{
    //    padding: 6px 10px;
    //  }
    //  td:nth-child(5){
    //    text-align: center !important;
    //  }
    //  td:nth-child(6){
    //    text-align: center !important;
    //  }
    //  td:nth-child(7){
    //    text-align: left !important;
    //  }
    //  td:nth-child(8){
    //    text-align: left !important;
    //  }
    //}

  }

  &-course-schedule{
    &__header{
      align-items: center;
    }

    .cell{
      &-action{
        width: 26%;
      }

      &-course{
        width: 12%;
      }

      &-status{
        width: 7%;
      }

      &-departmen{
        width: 22% !important;
        @include mq('1300', max) {
          width: 22%;
        }
      }

    }

    tr{
      th{
        padding: 6px 10px;
      }
      td{
        padding: 6px 10px;
      }
      td:nth-child(5){
        text-align: center !important;
      }
      td:nth-child(6){
        text-align: center !important;
      }
      td:nth-child(7){
        text-align: left !important;
      }
      td:nth-child(8){
        text-align: left !important;
      }
    }

  }

  &-schedule-people{
    &__header{
      align-items: center;
      @include mq('1025', max) {
        flex-direction: column;
        justify-content: flex-start;
      }

      .btn-groups{
        margin-bottom: 20px;
        @include mq('768', max) {
          display: flex;
          flex-wrap: wrap;
        }

        .btn-add{
          @include mq('768', max) {
            margin-bottom: 15px;
          }
        }
      }
    }

    .cell{
      &-action{
        width: 17%;
      }

      &-course{
        width: 55%;
      }

      &-position{
        width: 15%;
      }

      &-id{
        width: 13%;
      }
    }
  }

  &-exams{
    &__header{
     align-items: center;
      .btn-add{
        margin-right: 12px;
      }
    }

    .cell{
      &-action{
        width: 25%;
      }

      &-course{
        width: 22%;
      }

      &-exam{
        width: 16%;
      }

      &-date{
        width: 14%;
      }

      &-code{
        width: 7%;
      }

      &-status{
        width: 8%;
      }

      &-start-date{
        width: 11%;
      }

      &-date{
        width: 11%;
      }
    }

    td.cell-date,
    td.cell-start-date{
      text-align: center !important;
    }
  }

  &-exams-questions{
     .cell{
       &-action{
         width: 22%;
       }

       &-number{
         width: 8%;
       }

       &-question{
         width: 52%;
       }

       &-section{
       }

       &-answers{
       }
     }

    tr{
      td:nth-child(3){
        text-align: center !important;
      }

      td:nth-child(5){
        text-align: center !important;
      }

      td:nth-child(6){
        text-align: center !important;
      }
    }

    &__header{
      .btn-groups {
        margin-bottom: 20px;
      }
    }
  }

  &-student-exams{
    .cell{
      &-action{
        width: 18%;
      }

      &-student{
        width: 40%;
      }

      &-date{
        width: 14%;
      }
    }

    td.cell-date{
      text-align: center !important;
    }

    td.cell-courses{
      text-align: center !important;
    }

    td.cell-exams{
      text-align: center !important;
    }

    td.cell-taken{
      text-align: center !important;
    }

    .more-box{
      display: block;
      @include mq('1300', min) {
        display: none;
      }
    }

    &.table-exams-student{
      .cell-date{
        width: 12%;
      }

      .cell-student{
        width: 12%;
        text-align: center !important;
      }
    }
  }

  &-student-exams-list{
    td.cell-checkbox{
      text-align: left;
      .jcf-checkbox{
        margin-left: 3px;
      }
    }

    .more-box{
      display: block;
      @include mq('1300', min) {
        display: none;
      }
    }

    &__header{
      justify-content: flex-end;
    }

    .cell{
      &-action{
        width: 22%;
      }

      &-score{
        width: 10%;
      }

      &-status{
          width: 10%;
      }

      &-data{
        width: 15%;
      }
    }
  }

  &-exam-report{
    .cell{
      &-number {
        width: 5%;
        text-align: center;
        vertical-align: top;
      }

      &-question {
        width: 95%;
        text-align: left;
        padding: 0;

        &__title{
          margin: 10px 0;
          font: 700 16px/1 $ff-khula;
          color: $c-black;
          padding: 0 15px;
        }

        &__text{
          padding: 6px 15px;
          margin-bottom: 10px;
        }

        &__list{
          margin-left: 40px;
          li{
            padding: 10px;
            display: flex;
            justify-content: space-between;

            &:nth-child(odd){
                background-color: #f2f2f2;
            }

            span{
              display: block;
              width: calc(100% - 45px);

              strong{
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    th.cell-question{
      padding: 6px 15px;
    }
  }
}

.table-form{
  display: flex;
  flex-direction: row;
  align-items: center;
  @include mq('768', max) {
    flex-direction: column;
    align-items: flex-start;
  }
  &__label{
    font-size: 16px;
    color: $c-black;
    font-family: $ff-khula;
    font-weight: 600;
    padding-right: 8px;
    @include mq('768', max) {
      margin-bottom: 10px;
    }
  }
  .select2-container {
    width: 363px !important;
    height: 32px !important;
    @include mq('768', max) {
      width: 270px !important;
    }
  }
}

table.table_border{
  .cell--center{
    text-align: center !important;
  }
}

.more__drop-down .more__item a.btn-more{
  display: none;
  @include mq('1300', max) {
    display: flex;
    padding: 6px 5px;
  }
}

.actions  .btn-table{
  @include mq('1300', max) {
    display: none !important;
  }
}

table .actions .more-box >.btn-table{
  @include mq('1300', max) {
    display: block !important;
    //font-size: 0;
    width: 75px;
    &:before{
      //content: 'Actions';
      display: inline-block;
      font-weight: 500;
      font-size: 14px;
      font-family: "PT Sans",sans-serif;
    }
  }
}

table.table-checkpoint .actions .more-box{
  display: none;
  @include mq('1300', max) {
    display: block;
  }
}

.table-questions{
  strong{
    font: 600 16px/1 $ff-khula;
    color: $c-black;
    display: inline-block;
    margin-right: 10px;
  }

  font: 400 16px/1 $ff-khula;
  color: $c-black;
  margin-bottom: 20px;
}

table.table_border{
  th.cell__info{
    position: relative;

    .cell-status-info{
      display: none;
      padding: 14px 7px;
      font: 400 16px/1 $ff-khula;
      color: #4a4a4a;
      background-color: #f8f8f8;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
      position: absolute;
      right: 0;
      top: 36px;
      z-index: 99;
      &:after{
        content: '';
        width: 8px;
        height: 8px;
        background-color: #f8f8f8;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        right: 40px;
        transform: rotate(-45deg);
        top: -5px;
        z-index: 98;
      }
      &:before{
        content: '';
        width: 20px;
        height: 8px;
        position: absolute;
        right: 35px;
        background-color: #f8f8f8;
        top: 0;
        z-index: 100;
      }
    }

    &:hover{
      cursor: pointer !important;

      .cell-status-info{
        display: block;
      }
    }
  }
}

// table exams answers
.table{
  &-exams-answers{

    .btn-delete{
      padding: 1px 12px 2px;
      height: 28px;
      position: relative;
      top: 1px;
    }

    .btn-edit{
        &--change{
          background:#fff url('/img/design/aoe-pencil-button.png') 15% center no-repeat;

          &:hover{
            background:#fff url('/img/design/aoe-pencil-button-hover.png') 15% center no-repeat;
          }

          &.not-allowed{
            background:#fff url('/img/design/aoe-pencil-button-allowed.png') 15% center no-repeat;
          }
        }
    }

    .cell{
      &-action{
        width: 16%;
      }
    }

    .cell{

      &-answer{
        width: 8%;
        text-align: center !important;
        div{
          &:nth-child(1){
            margin-bottom: 15px;
          }
        }

        label{
          font: 400 16px/1.2 $ff-khula;
          color: $c-black;
        }

        .jcf-radio{
          margin-right: 10px;
          background-color: #f0eeee;
          border: solid 1px #979797;

          &:hover{
            cursor: pointer !important;
          }

          span{
            background-color: $c-green;
          }

          &.jcf-checked{
            border-color: $c-green;
          }
        }
      }

      &-question{
        text-align: left !important;
      }
    }

    .edit-controls{
      button{
        padding: 10px 0;
        width: 74px;
        text-align: center;
        font: 400 16px/1 $ff-khula;
        border-radius: 3px;

        &:hover{
          cursor: pointer;
        }

        &:nth-child(1){
          margin-right: 10px;
        }

        &.edit-ok{
          background-color: $c-green;
          color: $c-white;
          &:hover{
            background-color: rgba(46,179,152,.6);
          }
        }

        &.edit-cancel{
          background-color: #f0eeee;
          border: solid 1px #9b9b9b;
          color: $c-black;
          &:hover{
            background-color: rgba(240,238,238,.6);
          }
        }
      }
    }

  }

  .edit-area{
    width: 100%;
    min-height: 140px;
    padding: 8px;
    border-radius: 3px;
    border: solid 1px #4990e2;
    font: 400 16px/1.2 $ff-khula;
    resize: none;

    &:focus{
      outline: none;
      box-shadow: 0 0 1px 1px rgba(73, 144, 226, 0.41), inset 0 0 1px 0 rgba(0, 0, 0, 0.5);
      border: solid 1px #4990e2;
    }
  }

}

// table table multiselect
.table-multiselect{
  &__header{
    display: flex;
    flex-direction: row-reverse;

    div{
      display: flex;
      flex-direction: column;
    }

    .multiselect{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 12px;

      &__label{
        margin-right: 5px;
        font: bold 16px/1 $ff-khula;
        color: $c-black;
      }

      &-box{
        width: 332px;
        height: 32px;
        border-radius: 3px;
        background-color: #ffffff;
        border: solid 1px #c5c3c6;
        padding:0 35px 0 10px;
        position: relative;

        &__btn{
          width: 100%;
          height: 100%;
          @include centerer-y;
          right: 0;
          z-index: 112;
          background-color: transparent;

          &-arrow{
            position: absolute;
            top: 13px;
            right: 10px;
            display: inline-block;
            @include triangle(7px, down, $c-black);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
          }

          &:hover{
            cursor: pointer;
          }
        }

        &__text{
          display: inline-block;
          font: 400 16px/2 $ff-khula;
          color: $c-black;
          overflow: hidden;
          position: relative;
          z-index: 89;
        }

        &__submenu{
          display: none;
          padding: 12px 10px;
          left: 0;
          top: 32px;
          position: absolute;
          width: 100%;
          border-radius: 3px;
          background-color: #ffffff;
          border: solid 1px #c5c3c6;
          z-index: 113;

          &--checkbox{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 10px;

            .jcf-checkbox{
              margin-right: 10px;
            }

            label{
              font: 16px/1 $ff-khula;
              color: $c-black;
            }

            &:last-child{
              margin-bottom: 0;
            }
          }

          &.show{
            display: block;
          }

          &--btn{
            margin: 15px 0 5px;
            display: block;
            text-align: center;

            .btn-apply{
              display: inline-block;
              font: 500 16px/34px $ff-khula;
              border-radius: 3px;
              padding: 0 22px;
              height: 34px;
              background-color: #2eb398;
              color: #fff;

              &:hover{
                background-color: rgba(46,179,152,.6);
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  th{
    font-size: 14px !important;

    &.cell{
      &-action{
        width: 10% !important;
      }
    }
  }

  td{
    font-size: 14px !important;
    text-align: left !important;

    &.cell{
      &-date{
        width: 11%;
      }

      &-info{
        &__column-1{
          width: 79%;
        }

        &__column-2{
          width: calc(79% / 2);
        }

        &__column-3{
          width: calc(79% / 3);
        }

        &__column-4{
          width: calc(79% / 4);
        }

        &__column-5{
          width: calc(79% / 5);
        }

        &__column-6{
          width: calc(79% / 6);
        }

        &__column-7{
          width: calc(79% / 7);
        }
      }
    }

    .jcf-checkbox{
      input[type=text]{
        font-size: 14px;
      }

      &:hover{
        cursor: pointer !important;
      }
    }
  }

  tbody{
    td{
      button.btn-table{
        @include mq('1300', max) {
          display: block !important;
        }
      }
    }
  }


}


table.table_border{
  .more__drop-down{
    &_wide{
      width: 165px;
    }
  }
}

// table monitor status
.table-monitor-status{
  .cell{
      &-action{
          width: 20%;
      }

      &-status{
        width: 8%;
      }

      &-monitor{
        width: 24%;
      }

      &-cell-debug{
        width: 24%;
      }

      &-max-update{
        width: 24%;
      }
  }

  th.cell-status{
  }

  td.cell-status{
      text-align: center !important;
  }

  .status{
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #c2bebe;
    border-radius: 50%;

    &_active{
      background-color: #74b65f;
    }
  }
}

.table-bottom{
  .jcf-select-drop .jcf-select-drop-content{
     border: 1px solid #c6c3c6 !important;
  }

  .jcf-list-box .jcf-selected, .jcf-select-drop .jcf-hover{
    background-color: #d5f0ea !important;
  }

  .jcf-select-drop .jcf-list ul li .jcf-option{
    color: #4a4a4a !important;
    cursor: pointer;
  }

  .jcf-select {
    &:hover{
      cursor: pointer;
    }

    &.jcf-focus {
      .jcf-select-opener {
        &:before {
          border-top: 6px solid #9b9b9b;
        }
      }
    }
  }
}

