.preloader{
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 101;

    &.show{
        display: block;
    }

    &-box{
        @include centerer-xy;
        z-index: 102;
        padding: 20px 0 0 0;
        width: 416px;
        height: 188px;
        border-radius: 3px;
        background-color: #ffffff;
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.34);

        &__text{
            margin-top: 15px;
            text-align: center;
            font:400 18px/1 $ff-khula;
            color: $c-black;
        }
    }

    #floatingCirclesG{
        position:relative;
        width:115px;
        height:115px;
        margin:auto;
        transform:scale(0.6);
        -o-transform:scale(0.6);
        -ms-transform:scale(0.6);
        -webkit-transform:scale(0.6);
        -moz-transform:scale(0.6);
    }

    .f_circleG{
        position:absolute;
        background-color:rgb(255,255,255);
        height:21px;
        width:21px;
        border-radius:11px;
        -o-border-radius:11px;
        -ms-border-radius:11px;
        -webkit-border-radius:11px;
        -moz-border-radius:11px;
        animation-name:f_fadeG;
        -o-animation-name:f_fadeG;
        -ms-animation-name:f_fadeG;
        -webkit-animation-name:f_fadeG;
        -moz-animation-name:f_fadeG;
        animation-duration: 1s;
        animation-iteration-count:infinite;
        -o-animation-iteration-count:infinite;
        -ms-animation-iteration-count:infinite;
        -webkit-animation-iteration-count:infinite;
        -moz-animation-iteration-count:infinite;
        animation-direction:normal;
        -o-animation-direction:normal;
        -ms-animation-direction:normal;
        -webkit-animation-direction:normal;
        -moz-animation-direction:normal;
    }

    #frotateG_01{
        left:0;
        top:47px;
        animation-delay:0.45s;
        -o-animation-delay:0.45s;
        -ms-animation-delay:0.45s;
        -webkit-animation-delay:0.45s;
        -moz-animation-delay:0.45s;
    }

    #frotateG_02{
        left:13px;
        top:13px;
        animation-delay:0.6s;
        -o-animation-delay:0.6s;
        -ms-animation-delay:0.6s;
        -webkit-animation-delay:0.6s;
        -moz-animation-delay:0.6s;
    }

    #frotateG_03{
        left:47px;
        top:0;
        animation-delay:0.75s;
        -o-animation-delay:0.75s;
        -ms-animation-delay:0.75s;
        -webkit-animation-delay:0.75s;
        -moz-animation-delay:0.75s;
    }

    #frotateG_04{
        right:13px;
        top:13px;
        animation-delay:0.9s;
        -o-animation-delay:0.9s;
        -ms-animation-delay:0.9s;
        -webkit-animation-delay:0.9s;
        -moz-animation-delay:0.9s;
    }

    #frotateG_05{
        right:0;
        top:47px;
        animation-delay:1.05s;
        -o-animation-delay:1.05s;
        -ms-animation-delay:1.05s;
        -webkit-animation-delay:1.05s;
        -moz-animation-delay:1.05s;
    }

    #frotateG_06{
        right:13px;
        bottom:13px;
        animation-delay:1.2s;
        -o-animation-delay:1.2s;
        -ms-animation-delay:1.2s;
        -webkit-animation-delay:1.2s;
        -moz-animation-delay:1.2s;
    }

    #frotateG_07{
        left:47px;
        bottom:0;
        animation-delay:1.35s;
        -o-animation-delay:1.35s;
        -ms-animation-delay:1.35s;
        -webkit-animation-delay:1.35s;
        -moz-animation-delay:1.35s;
    }

    #frotateG_08{
        left:13px;
        bottom:13px;
        animation-delay:1.5s;
        -o-animation-delay:1.5s;
        -ms-animation-delay:1.5s;
        -webkit-animation-delay:1.5s;
        -moz-animation-delay:1.5s;
    }



    @keyframes f_fadeG{
        0%{
            background-color:rgb(71,158,254);
        }

        100%{
            background-color:rgb(255,255,255);
        }
    }

    @-o-keyframes f_fadeG{
        0%{
            background-color:rgb(71,158,254);
        }

        100%{
            background-color:rgb(255,255,255);
        }
    }

    @-ms-keyframes f_fadeG{
        0%{
            background-color:rgb(71,158,254);
        }

        100%{
            background-color:rgb(255,255,255);
        }
    }

    @-webkit-keyframes f_fadeG{
        0%{
            background-color:rgb(71,158,254);
        }

        100%{
            background-color:rgb(255,255,255);
        }
    }

    @-moz-keyframes f_fadeG{
        0%{
            background-color:rgb(71,158,254);
        }

        100%{
            background-color:rgb(255,255,255);
        }
    }
}