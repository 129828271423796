.header{
  flex: 0 0 auto;
  background-color: $c-white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  z-index: 90;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &-main{
    padding: 4px 30px 5px;
    justify-content: space-between;
    align-items: center;
    @include mq('1400', max) {
      padding: 4px 15px 5px;
    }
    @include mq('1300', max) {
      padding: 4px 15px 5px;
    }
    @include mq('1025', max) {
      position: relative;
    }

    .login{
      display: flex;
      flex-direction: row;
      text-decoration: none;
      align-items: center;

      .login-text{
        padding-left: 12px;
        span{
          display: block;
          text-align: center;
          color: #294572;
        }

        span:nth-child(1){
          font-size: 24px;
          line-height: 0.83;
          letter-spacing: 2.1px;
          font-family: $ff-main;
        }

        span:nth-child(2){
          font-size: 18px;
          line-height: 1.11;
        }

      }

    }

    .nav{
      @include mq('1025', max) {
        background-color: $c-white;
        position: absolute;
        width: 248px;
        min-height: calc(100vh - 114px);
        top: 58px;
        left: -248px;
        z-index: 88;
        transition: all 0.4s ease-in-out;
      }
      @include mq('480', max) {
        width: 200px;
        left: -200px;
      }
      &.active{
        @include mq('1025', max) {
          left: 0;
        }
      }
      &__list{
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        @include mq('1025', max) {
          display: block;
        }
      }

      &__item{
        margin: 0 20px;
        position: relative;
        @include mq('1300', max) {
          margin: 0 15px;
        }
        @include mq('1025', max) {
          margin: 0;
          border-bottom: 1px solid #c6c3c6;
        }

        &:nth-child(4){
          .nav__icon--mob{
            svg{
              width: 27px;
              height: 27px;
            }
          }
        }
        &:nth-child(5){
          .nav__icon--mob{
            svg{
              width: 20px;
              height: 20px;
            }
          }
        }
        &:nth-child(6){
          .nav__icon--mob{
            svg{
              width: 20px;
              height: 20px;
            }
          }
        }
        &:nth-child(7){
          .nav__icon--mob{
            svg{
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      &__link{
        display: inline-block;
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;
        color: $c-black;
        font-family: $ff-khula;
        position: relative;
        &:before{
            content:' ';
            position: absolute;
            width: 100%;
            display: block;
            height: 2px;
            bottom: -2px;
            background-color: $c-blue;
            z-index: 99;
            opacity: 0;
            @include mq('1025', max) {
              display: none;
            }
        }
        &:hover{
          color: $c-blue;
          transition: $base-transition;
          &:before{
            opacity: 1;
          }

          .nav__icon{
            border-top: 6px solid $c-blue;
          }

          @include mq('1025', max) {
            .nav__icon--mob{
              svg{
                fill: $c-blue;
              }
            }
          }
        }
        @include mq('1025', max) {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 15px;
        }

        &--active{
          color: $c-blue;
          transition: $base-transition;
          @include mq('1025', max) {
            background-color: $c-blue;
            color: $c-white !important;
            .nav__icon--mob{
              svg{
                fill: $c-white !important;
              }
            }
          }
          &:before{
            opacity: 1;
          }
          .nav__icon{
            border-top: 6px solid $c-blue;
          }
        }

      }

      &__icon{
        display: inline-block;
        @include triangle(4px, down, $c-black);
        border-top: 6px solid #4a4a4a;
        position: relative;
        top: -2px;
        @include mq('1025', max) {
          display: none;
        }
        &--mob{
          display: none;
          @include mq('1025', max) {
            display: inline-block;
            padding-right: 10px;
          }
          svg{
            @include mq('1025', max) {
              width: 22px;
              height: 22px;
              fill: $c-black;
            }
          }
        }
      }


      &-drop{
        display: none;
        position: absolute;
        left: 0;
        top: 38px;
        z-index: 120;
        width: 168px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
        background-color: $c-white;
        &-mob--active{
          @include mq('1025', max) {
            position: static;
            top: auto;
            left: auto;
            box-shadow: none;
            width: auto;
            background-color: #f0eeee;
            border-left: 3px solid $c-blue;
          }
        }

        &.active{
          display: block;
        }

        &__item{
          width: 100%;
          @include mq('1025', max) {
            border-top: 1px solid rgba(197, 195, 198, 0.5);
          }
        }

        &__link{
          display: block;
          width: 100%;
          //padding: 10px 15px;
          font-weight: 400;
          color: $c-black;
          font-size: 16px;
          font-family: $ff-khula;
          text-decoration: none;
          &:hover{
            background-color: $c-blue;
            color: $c-white;
          }

          @include mq('1025', max) {
            padding: 5px 10px 5px 50px;
          }

          span{
            position: relative;
            z-index: 200;
            display: block;
            padding: 10px 15px;
          }
        }
      }

    }

    .user-box{
        position: relative;
    }

    .user{
      display: inline-block;
      text-decoration: none;

      .user-title{
        font-size: 16px;
        font-weight: 700;
        color: $c-blue;
        font-family: $ff-khula;
        @include mq('480', max) {
          display: none;
        }
      }

      .user-icon{
        position: relative;
        top: 4px;
         svg{
           width: 30px;
           height: 22px;
           fill: $c-blue;
         }
      }

      .user-arrow{
        display: inline-block;
        position: relative;
        left: -4px;
        top: -1px;
        @include triangle(4px, down, $c-blue);
        border-top: 6px solid #479efe;
      }

      &__drop-down{
        position: absolute;
        display: none;
        width: 168px;
        background-color: #ffffff;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
        z-index: 99;
        top: 42px;
        right: 0;
        @include mq('480', max) {
          right: -15px;
        }

      }

      &__list{
        margin: 0;
        display: flex;
        flex-direction: column;
      }

      &__item{
        width: 100%;
      }

      &__link{
        padding: 10px 22px 10px 22px;
        text-decoration: none;
        display: block;
        font-size: 16px;
        font-family: $ff-khula;
        color: $c-black;

        &--active{
          color: $c-white;
          background-color: $c-blue;
        }

        &:hover,
        &:focus{
          color: $c-white;
          background-color: $c-blue;
        }
      }
    }

    .user__drop-down.active{
      display: block;
    }

  }
}



.logo{
  &-login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    img{
      padding-top: 5px;
    }
    div{
      text-align: center;
    }
    span{
      font-size: 22px;
      color: #294572;
      font-family: $ff-main;
      display: inline-block;
      padding-bottom: 5px;
      font-weight: 300;
      @include mq('768', max) {
        font-size: 18px;
      }
    }
  }

}

.menu-btn {
  display: none;
  width: 24px;
  height: 30px;
  position: relative;
  margin-right: 106px;
  @include mq('1025', max) {
      display: block;
  }
  @include mq('768', max) {
    margin-right: 56px;
  }
  @include mq('480', max) {
    margin-right: 0;
  }
}

.menu-btn span {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -2px;
}

.menu-btn span{
  background-color: $c-blue;
}

.menu-btn span,
.menu-btn span:before,
.menu-btn span:after {
  position: absolute;
  display: block;
  content: '';
  height: 4px;
  width: 24px;
  right: 0;
  background: $c-blue;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.menu-btn span{
  background-color: $c-blue;
}

.menu-btn span:before {
  top: -7px;
}

.menu-btn span:after {
  bottom: -7px;
}

.menu-btn.active span {
  background-color: transparent;
  box-shadow: none;
}

.menu-btn.active span:before {
  top: 0;
  left: 0;
  box-shadow: none;
  background-color: $c-blue;
}

.menu-btn.active span:after {
  top: 0;
  right: 0;
  box-shadow: none;
  background-color: $c-blue;
}

.menu-btn.active span:before {
  transform: rotate(45deg);
}

.menu-btn.active span:after {
  transform: rotate(-45deg);
}

.menu-btn.active{

}