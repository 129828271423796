*{
  box-sizing: border-box;
}


html,
body {
  height: 100%;
  -webkit-overflow-scrolling:touch;
}


ul,
ol {
  list-style-type: none;
  padding-left: 0;
}

.wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media screen and (max-height: 650px){
    display: block;
    min-height: 100vh;
  }

}

input{
  &:focus,
  &:hover,
  &:active{
    outline: none;
  }
}

input {outline: none;}
input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important;
  -webkit-text-fill-color: #999;
  color: #999 !important;
}

button{
  border: none;

  &:focus,
  &:active,
  &:hover{
    outline: none;
  }
}

a{
  text-decoration: none;
  &:hover{
    transition: $base-transition;
    outline: none;
  }

  &:focus,
  &:active{
    outline: none;
  }
}

.grid{
  width: 1300px;
  margin: 0 auto;
  @include mq('1400', max) {
    padding: 0 15px 0;
  }

  @include mq('1300', max) {
    max-width: 100%;
  }

  &--pad-small{
    padding: 25px 0;
    @include mq('1300', max) {
      padding: 25px 15px;
    }
  }

  &--pad-middle{
    padding: 32px 0 25px;
    @include mq('1300', max) {
      padding: 32px 15px 25px;
    }
  }
}