.error {
  position: relative;

  &-inner {
    @include centerer-xy;
    text-align: center;

    h2 {
      font-size: 24px;
      color: $c-black;
      font-weight: bold;
      font-family: $ff-khula;
      margin-bottom: 8px;
    }

    p {
      font-size: 24px;
      color: $c-black;
      font-weight: 400;
      font-family: $ff-khula;
      margin-bottom: 25px;
    }
  }

  &-box {
    margin: 0 auto;
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
  }

  &-title {
    margin-bottom: 30px;
    font-family: 'Khula';
    font-weight: 300;
    font-size: 46px;
    line-height: 74px;
    color: #424242;
  }

  &-text {
    width: 580px;
    margin-bottom: 50px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #424242;
  }

  &-number {
    width: 482px;
    height: 260px;
    font-family: 'Outfit';
    font-weight: 800;
    font-size: 260px;
    line-height: 100%;
    text-align: center;
    color: #479EFE;
  }

  &-btn {
    width: 176px;
    height: 42px;
    padding: 5px 35px;
    text-align: center;
  }
}