.main-login{
  background: url("/img/design/aoe-login-bg.png") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  @media screen and (max-height: 700px){
    display: block;
  }
  &__inner{
    flex: 1 0 auto;
    position: relative;
    @media screen and (max-height: 700px){
      display: block;
    }
  }

  .main__info-bottom{
    flex: 0 0 auto;
    @media screen and (max-height: 700px){
      display: block;
    }
    p{
      text-align: center;
      width: 938px;
      padding: 0 10px;
      margin: 0 auto;
      bottom: 12px;
      font-size: 14px;
      line-height: 1.14;
      color: #2e3d49;
      padding-bottom: 10px;
      font-family: $ff-khula;
      @include mq('1025', max) {
        width: 100%;
      }
    }
  }
}

.login-form-wrapper{
  width: 400px;
  @include centerer-xy;
  @media screen and (max-height: 700px){
    position: static;
    transform: none;
    left: auto;
    top: auto;
    margin: 0 auto;
  }
  @include mq('768', max) {
    width: 300px;
  }

  &__title{
    line-height: 1.6;
    text-align: center;
    color: $c-black;
    font-family: $ff-khula;
    font-weight: 300;
    font-size: 30px;
    margin: 0;
    @media screen and (max-height: 700px){
      padding-top: 15px;
    }
    @include mq('768', max) {
      font-size: 25px;
    }
  }
}

.login-form{
  width: 100%;
  background-color: $c-white;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
  @media screen and (max-height: 700px){
    margin-bottom: 20px;
  }

  &__title{
    text-align: center;
    height: 64px;
    line-height: 64px;
    background-color: #479efe;
    font-family: $ff-khula;
    font-size: 24px;
    font-weight: 300;
    color: $c-white;
    @media screen and (max-height: 700px){
      height: 50px;
      line-height: 50px;
    }
    @include mq('768', max) {
      font-size: 20px;
    }
  }

  &__main{
    padding: 40px 40px 45px 40px;
    @include mq('768', max) {
      padding: 20px 20px 25px 20px;
    }

    fieldset{
      border: none;
      padding: 0;
      margin-bottom: 40px;
      position: relative;

      &.active{
        label{
          top: -8px;
          font-size: 12px;
          padding: 0 3px;
          color: #656363;
          z-index: 10;
        }
      }

      &.has-error{
          margin-bottom: 24px;

        .help-block{
          display: block;
          color: #f8092d;
          font-size: 12px;
          font-family: $ff-khula;
          padding-top: 4px;
          @include mq('768', max) {
            font-size: 10px;
          }
        }

        .login-form__input{
          border-color: #f8092d;
        }

      }

      .help-block{
        display: none;
      }

    }
  }

  &__input{
    width: 100%;
    border-radius: 3px;
    border: solid 1px #4a4a4a;
    height: 40px;
    padding: 0 38px 0 15px;
    transition: all .24s;
    font-size: 14px;
    font-weight: 300;
    color: #4a4a4a;
    position: relative;
    z-index: 3;
    background: transparent;
  }

  label{
    transition: all .24s;
    font-size: 14px;
    color: #4a4a4a;
    font-family: $ff-khula;
    font-weight: 400;
    position: absolute;
    left: 15px;
    margin: 0;
    top: 14px;
    background-color: #fff;
    cursor: text;
    z-index: 2;
  }

  &__group-btn{
    display: flex;
    justify-content: space-between;
  }

  &-btn{
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    background-color: #589228;
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    color: $c-white;
    font-family: $ff-khula;
    width: 100%;
    @include mq('768', max) {
      font-size: 16px;
    }

    &:hover{
      background-color: #589228;
      opacity: 0.75;
      cursor: pointer;
    }

    &_confirm,
    &_cancel{
      display: inline-block;
      width: calc(50% - 6px);
    }


    &_cancel{
      background-color: #f0eded;
      border: solid 1px #c5c3c6;
      color: $c-black;

      &:hover{
        background-color: rgba(240,238,238,.6);
        cursor: pointer;
      }
    }

    &_confirm{
      border: solid 1px #589228;
    }
  }

  //--pass-chane
  &--pass{
    .login-form__main{
      padding: 30px 40px 30px;

      &--email{
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
        color: #4a4a4a;
        font-family: $ff-khula;
        font-weight: 400;
      }
    }
    fieldset{
      margin-bottom: 33px !important;
    }
  }

  &__icon{
    width: 44px;
    height: 40px;
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
    padding: 0 10px;
    background: url("/img/design/svg/close-eye.svg") no-repeat center;

    &_show{
      background: url("/img/design/svg/eye.svg") no-repeat center;
    }

    svg{
      width: 18px;
      height: 40px;
      fill: #ccc;
    }

    &:hover{
      cursor: pointer;
    }

    &.show{
      display: block;
    }

    &.active{
      svg{
        fill: #898686;
      }
    }
  }
}


.login-form__main-bottom{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;

  a{
    font-family: $ff-khula;
    text-decoration: none;
    font-size: 14px;
    color: #0c7dda;
    position: relative;
    top: 3px;
    @include mq('768', max) {
      font-size: 12px;
    }
    &:hover{
      text-decoration: underline;
    }
  }
}


.login-form__input--checkbox{
  width: 24px;
  height: 24px;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #4a4a4a;
}


.login-form__input--checkbox:checked{

}


.form-checkbox{
  position: relative;
}


.form-checkbox label{
  position: relative;
  padding-left: 25px;
  line-height: 16px;
  left: auto;
  top: auto;
  font-family: $ff-khula;
  font-size: 14px;
  color: #4a4a4a;
  padding-top: 1px;
  @include mq('768', max) {
    font-size: 12px;
  }
}


.form-checkbox label:before{
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #4a4a4a;
  position: absolute;
  left: 0;
  top: 1px;
  @include mq('768', max) {
    font-size: 12px;
  }
}


.form-checkbox input{
  width: 16px;
  height: 16px;
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: 99;
}


.form-checkbox input:hover{
  cursor: pointer;
}


.form-checkbox input:checked + label:before{
  border: solid 1px #4990e2;
  background: url("/img/design/aoe-login-checkbox-selected.png") no-repeat center;
}


.form-checkbox label:hover {
  cursor: pointer;
}
