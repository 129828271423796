.btn-groups{
  text-align: center;
}


.alignment-btn{
  text-align: center;
}


.btn-back{
  display: inline-block;
  margin: 15px 0 0;
  font-family: $ff-khula;
  font-size: 18px;
  color: #4990e2;
  font-weight: 400;
  text-decoration: none;
  &:hover{
    opacity: 0.6;
  }
}

.btn-add{
  display: inline-block;
  padding: 0 12px;
  height: 34px;
  background-color: $c-green;
  border-radius: 3px;
  color: $c-white;
  font-family: $ff-khula;
  font-size: 16px;
  font-weight: 500;
  line-height: 34px;
  @include mq('768', max) {
    margin-bottom: 15px;
  }
  &:hover,
  &:focus{
    background-color: rgba(46, 179, 152, 0.6);
    cursor: pointer;
  }

  &.not-allowed{
    background-color: rgba(74, 74, 74, 0.25);
    color: $c-white;
    &:hover,
    &:focus,
    &:active{
      cursor: not-allowed;
      background-color: rgba(74, 74, 74, 0.25);
    }
  }
}

.btn-active{
  display: inline-block;
  background: transparent;
  font-size: 16px;
  font-family: $ff-khula;
  font-weight: 400;
  color: $c-black;
  &:hover{
    cursor: default;
  }
}

.btn-pdf{
  height: 32px;
  padding: 0 15px 0 25px;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #9b9b9b;
  font: 16px/1 $ff-khula;
  color: $c-black;
  position: relative;

  svg{
    width: 16px;
    height: 16px;
    @include centerer-x;
    left: 10px;
  }

  &:first-child{
    margin-right: 10px;
  }

  &:hover{
    cursor: pointer;
  }

}

.btn-refresh{
  display: inline-block;
  background: transparent;

  &:hover{
    cursor: pointer;

    svg{
      fill: $c-green;
    }
  }

  svg{
    width: 22px;
    height: 16px;
    fill: $c-black;
  }

}


.btn-delete{
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  font-family: $ff-khula;
  color: #434343;
  white-space: nowrap;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #979797;

  svg{
    display: inline;
    width: 16px;
    height: 16px;
    fill: $c-red;
    position: relative;
    top: 2px;
  }

  span{
    display: inline-block;
    margin-left: 4px;
  }

  &:hover{
    cursor: pointer;
  }

  &--group {
    display: none;

    &.show {
      display: inline-block;
    }
  }

  &.not-allowed{
    color: #c6c3c6 !important;
    border: 1px solid !important;
    svg{
      fill: #c6c3c6 !important;
    }

    &:hover {
      cursor: not-allowed;
    }
  }
}


.btn-small{
  display: inline-block;
  width: 78px;
  height: 32px;
  font-size: 16px;
  font-weight: 400;
  font-family: $ff-khula;
  color: $c-white;
  border-radius: 3px;
  margin-right: 18px;

  &.not-allowed{
    background-color: rgba(74, 74, 74, 0.25);
    color: $c-white;
    &:hover{
      cursor: not-allowed;
    }
  }
}

.btn-middle{
  display: inline-block;
  width: auto;
  padding: 0 20px;
  height: 32px;
  font-size: 16px;
  font-weight: 400;
  font-family: $ff-khula;
  color: $c-white;
  border-radius: 3px;

  &.not-allowed{
    background-color: rgba(74, 74, 74, 0.25);
    color: $c-white;
    &:hover{
      cursor: not-allowed;
    }
  }
}

.btn{
  &--green{
    background-color: $c-green;

    &:hover,
    &:focus{
      background-color: rgba(46, 179, 152, 0.6);
      cursor: pointer;
    }
  }

  &--blue{
    background-color: $c-blue;

    &:hover,
    &:focus{
      background-color: rgba(71, 158, 254, 0.6);
      cursor: pointer;
    }
  }

  &--red{
    background-color: $c-error;

    &:hover,
    &:focus{
      background-color: rgba(243, 94, 94, 0.6);
      cursor: pointer;
    }
  }

  &--grey{
    background-color: #f0eeee;
    border: solid 1px #9b9b9b;
    color: $c-black;
    &:hover{
      background-color: rgba(240, 238, 238, 0.6);
      cursor: pointer;
    }
  }

  &-error{
    line-height: 32px;
  }
}

.icon-selected{
  svg{
    width: 20px;
    height: 20px;
    fill: $c-green;
  }
}

button.login-form-btn_cancel {
  &.not-allowed{
    pointer-events: none;
    opacity: 0.7;
    color: $c-black;
    &:hover,
    &:focus,
    &:active{
      cursor: not-allowed;
      background-color: rgba(74, 74, 74, 0.25);
    }
  }
}

button.login-form-btn {
  &.not-allowed {
    pointer-events: none;
    opacity: 0.7;
    color: white;
    &:hover,
    &:focus,
    &:active{
      cursor: not-allowed;
      background-color: rgba(74, 74, 74, 0.25);
    }
  }
}