.mfp-bg{
  opacity: 0.2;
}

.message{
  width: 466px;
  min-height: 188px;
  height: auto;
  border-radius: 3px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.34);
  @include centerer-xy;
  // overflow: hidden;
  padding-top: 18px;
  @include mq('480', max) {
    width: 100%;
  }

  &:before{
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 466px;
    height: 19px;
    background-color: $c-black;
    @include mq('480', max) {
      width: 100%;
    }
  }

  &-inner{
    min-height: 169px;
    height: auto;
    width: 100%;
    background-color: $c-white;
  }

  &-info{
    font-family: $ff-khula;
    font-size: 18px;
    color: $c-black;
    font-weight: 400;
    text-align: center;
    padding: 30px 50px 40px;
    margin: 0;
    line-height: 1.4;
    @include mq('480', max) {
      padding: 30px 30px 40px;
    }
  }

  &-error{
    &:before{
      background-color: #ea4444;
    }
  }

  &-success{
    &:before{
      background-color: #2eb398;
    }
  }

  .btn-groups{
    padding-bottom: 20px;

    .btn-small{
      margin: 0 9px;
    }
  }

  &-information{
    border-radius: 0;
    &:before{
      background-color: $c-white;
    }
  }

  &-register{
    border-radius: 0;
    padding-top: 0;
    &:before{
      display: none;
    }
    .message-info{
      padding: 30px 50px 30px;
    }

    &__box{
      padding: 0 50px 30px;

      span{
        font: 700 16px/1 $ff-khula;
        color: #4a4a4a;
      }
      input{
        width: 109px;
        height: 32px;
        border-radius: 3px;
        background-color: #ffffff;
        border: solid 1px #c6c3c6;
        padding-left: 8px;
        font: 400 16px/1 $ff-khula;
        color: #4a4a4a;
        @include placeholder(#ccc, 16px, 300)
      }
    }
  }

  &-export{
    @include mq('480', max) {
      margin-left: 0;
      align-items: center;
    }

    border-radius: 0;
    padding-top: 0;

      &:before{
        display: none;
      }

      &__header{
        width: 100%;
        height: 60px;
        background-color: #f0eded;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 15px;
        border: solid 1px #c5c3c6;

        h3{
          font:700 18px/1 $ff-khula;
          color: $c-black;
          margin-right: 2px;
        }

        ul{
          display: flex;
          >li+li:before {
            content: "/";
            color: $c-black;
            width: 8px;
            display: inline-block;
            padding: 0 5px;
            font-size: 16px;
            text-align: center;
          }

          li{
            font: 16px/1 $ff-khula;
            color: $c-black;

            @include mq('480', max) {
              flex-direction: column;
              font: 14px/1 $ff-khula;
            }
          }
        }
      }

      &__form{
        display: flex;
        flex-direction: column;
        padding: 25px 0 10px;
        margin-left: 50px;
        @include mq('480', max) {
          margin-left: 0;
          padding: 25px 20px 10px;
        }

        .form-group{
          @include mq('480', max) {
            flex-direction: row;
            align-items: center;
          }

          .form__label{
            @include mq('480', max) {
              width: 140px;
              padding-right: 15px;
            }
          }
        }
      }

    .btn-groups{
      display: flex;
      align-items: center;
      justify-content: center;

        .btn-middle{
          margin: 0 9px;
        }
    }
  }

  &-logs{
    width: 100%;
    max-width: 700px;

    @include mq('480', max) {
      margin-left: 0;
      align-items: center;
    }

    border-radius: 0;
    padding-top: 0;

    &:before{
      display: none;
    }

    &__header{
      width: 100%;
      height: 60px;
      background-color: #f0eded;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 15px;
      border: solid 1px #c5c3c6;
      @include mq('768', max) {
        height: 45px;
      }

      h3{
        font:700 18px/1 $ff-khula;
        color: $c-black;
        margin-right: 2px;
      }
    }

    &__main{
      height: 292px;
      overflow-y: scroll;
      padding: 6px 12px;

      p{
        font: 14px/1.4 $ff-khula;
        color: #64666e;
      }

      .time{
        color: $c-blue;
      }

    }

    &__form{
      display: flex;
      flex-direction: column;
      padding: 25px 0 10px;
      margin-left: 50px;
      @include mq('480', max) {
        margin-left: 0;
        padding: 25px 20px 10px;
      }

      .form-group{
        @include mq('480', max) {
          flex-direction: row;
          align-items: center;
        }

        .form__label{
          @include mq('480', max) {
            width: 140px;
            padding-right: 15px;
          }
        }
      }
    }

    .btn-groups{
      padding: 15px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq('768', max) {
        padding: 10px 0;
      }

      .btn-middle{
        margin: 0 9px;
      }
    }
  }

  &-screen{
    width: auto;

    @include mq('480', max) {
      margin-left: 0;
      align-items: center;
    }

    border-radius: 0;
    padding-top: 0;

    &:before{
      display: none;
    }

    &__header{
      width: 100%;
      height: 60px;
      background-color: #f0eded;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      border: solid 1px #c5c3c6;
      @include mq('768', max) {
        height: 45px;
      }


      h3{
        font:700 18px/1 $ff-khula;
        color: $c-black;
        margin-right: 2px;
      }

      span{
        font:700 18px/1 $ff-khula;
        color: $c-black;
      }
    }

    &__main{
      padding: 10px 28px;
      @include mq('768', max) {
        min-width: 480px;
        padding: 10px 18px;
      }
      @include mq('480', max) {
        min-width: 300px;
      }

      p{
        font: 14px/1.4 $ff-khula;
        color: #64666e;
      }

      .time{
        color: $c-blue;
      }

      img{
         //transform: scaleX(-1) rotate(90deg);
        
        
        @include mq('768', max) {
          display: block;
          max-width: 100%;
        }
      }

      .preloader{
        position: relative;
        width: 472px;
        height: 352px;
        background-color: transparent;
        @include mq('768', max) {
          width: auto;
          min-width: 280px;
        }

        &-box{
          box-shadow: none;
        }
      }

    }

    &__form{
      display: flex;
      flex-direction: column;
      padding: 25px 0 10px;
      margin-left: 50px;
      @include mq('480', max) {
        margin-left: 0;
        padding: 25px 20px 10px;
      }

      .form-group{
        @include mq('480', max) {
          flex-direction: row;
          align-items: center;
        }

        .form__label{
          @include mq('480', max) {
            width: 140px;
            padding-right: 15px;
          }
        }
      }
    }

    .btn-groups{
      padding: 0 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq('768', max) {
        padding: 10px 0;
      }

      .btn-middle{
        margin: 0 9px;
      }
    }
  }
}

.custom-kurwa-select{
  right: 30px;
  position: absolute;

  .jcf-select{
    width: 64px;
    height: 28px;
    border-radius: 3px;
    background-color: #ffffff;
    border: solid 1px #c6c3c6;
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    top: 7px;

    .jcf-select-opener{
      background: none;
      &:before{
        content: '';
        @include triangle(5px, down, #9b9b9b);
        border-top: 6px solid #9b9b9b;
        @include centerer-xy;
      }
    }

    &.jcf-focus{
      .jcf-select-opener{
        background: 0 0;
        &:before{
          content: '';
          @include triangle(5px, down, $c-green);
          border-top: 6px solid $c-green;
        }
      }
    }

    .jcf-select-text{
      margin: 0 26px 0 8px;
      span{
        font-size: 16px;
      }
    }
  }
}

.message-screen--front-camera.message-screen__main img {
  transform: rotate(180deg);
}

.message-screen__main--right img{
  transform: rotate(270deg);

}
.message-screen__main--left img{
  transform: rotate(90deg);
}
.message-screen__main--screenshot{
  width: 900px;
  display: flex;
  justify-content: center;
}
.message-screen__main--screenshot img{
  transform: scaleX(-1) rotate(-90deg);
}

.message-screen__main--container{
  display: flex;
  justify-content: space-between;
}
